import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { DetailContents, DetailTitleBox } from "component/board/item";
import { setDateTime } from "js/function";

const BoardDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;
    const historyType = window?.history?.state?.idx == 0 ? window?.history?.state?.idx : null;

    const pageDataApi = useGet({
        url:`/${type}/detail?${type}_id=${id}`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data ? pageDataApi?.data[type]||{} : {};

    useEffect(() => {
        if(pageDataApi?.data){
            if(!pageDataApi?.data[type]){
                navigate("/pageErr")
            }
        }
    }, [pageDataApi]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                addClass={type == "event" ? "notBg" : ""}
                pageBack={true}
                func={() => {
                    if(type == "event"){
                        navigate(-1);
                    }else if(historyType !== 0){
                        navigate(-1);
                    }else{
                        navigate("/notice");
                    }
                }}
                headTitle=""
                homeBtn={true}
            />
            <ContentsSection addClass={`bottomBasicPadding ${type == "event" ? "" : "headerContents"}`}>
                <DetailTitleBox
                    addClass={type == "event" ? "bgImgTitleBox" : ""}
                    bgImg={type == "event" ? pageData?.image_url : null}
                    title={pageData?.title}
                    date={type == "event" ?  `${pageData?.start_date ? setDateTime(pageData?.start_date,"yymmdd","hymd") : ""}${pageData?.start_date && pageData?.end_date ? " ~ " : ""}${pageData?.end_date ? setDateTime(pageData?.end_date,"yymmdd","hymd") : ""}` : pageData?.created_at ? setDateTime(pageData?.created_at,"yymmdd","hymd") : ""}
                />
                <DetailContents
                    contents={pageData?.contents}
                />
            </ContentsSection>
        </PageSizing>
    );
};

export default BoardDetail;