import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChkBox, CustomSelect } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox, ItemSection, ItemSectionTitle, PageSizing } from "component/app/items";
import { LoadingBox, Popup, RightPopup } from "component/basic/popup";
import { comFormat, getDateGap, setDateTime } from "js/function";
import { PaymentBox, PaymentItem, PaymentSuccess } from "component/payment/item";
import { DetailItem } from "component/detailing/item";

const DetailingPayment = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const location = useLocation();
    const pageData = location?.state ? location?.state : null;
    const pageDate = pageData?.date;
    const pageTime = pageData?.time;
    const productData = pageData?.productData;
    console.log(pageData)

    const cashApi = useGet({
        url:`/cash/remain`,
        loginType:"login"
    });
    const cashData = cashApi?.data?.cash;

    const couponApi = useGet({
        url:`/coupon?is_available=1&product_id=${id}`,
        loginType:"login"
    });
    const couponData = couponApi?.data?.coupons;

    const termsApi = useGet({
        url:"/term?type=pay",
    });
    const termsData = termsApi?.data?.terms;

    const [couponList,setCouponList] =  useState([]);
    const [coupon,setCoupon] =  useState({});

    const [paymentType,setPaymentType] =  useState("CARD");

    const [price,setPrice] =  useState(0);
    const [totalPrice,setTotalPrice] =  useState(0);

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [terms,setTerms] =  useState({});
    const [rightOpenType,setRightOpenType] =  useState(false);

    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [btnChk,setBtnChk] =  useState(true);

    const [paymentSuccessOpen,setPaymentSuccessOpen] =  useState(false);

    const [submitChk,setSubmitChk] =  useState(true);

    const paymentList = [{
        id: "CARD",
        name: `신용/체크카드`,
        icon: "/assets/images/icon/pay_cart.svg",
        disabled:false
    },{
        id: "CASH",
        name: `캐시 ${comFormat(cashData||0)}C`,
        icon: "/assets/images/icon/pay_cash.svg",
        disabled: (cashData||0) >= totalPrice ? false : true
    },{
        id: "NAVER",
        name: `네이버페이`,
        icon: "/assets/images/icon/pay_naver.svg",
        disabled:false
    },{
        id: "KAKAO",
        name: `카카오페이`,
        icon: "/assets/images/icon/pay_kakao.svg",
        disabled:false
    }]
    
    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
            termsData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
            idArr.push('month');
            textArr.push('현재 등록된 차량번호에 정기권이 귀속됨을 확인합니다')
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const rightOpen = (id,title,contents) => {
        let termsItem = {
            id:id,
            title:title,
            contents:contents
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    function payment(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("detailing_product_id", id);
            formData.append("date", pageDate);
            formData.append("time", pageTime);
            formData.append("payment_method", paymentType);

            if(coupon?.id){
                formData.append("user_coupon_id", coupon?.id);
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/detailing/reservation`,
                success: (data) => {
                    let payId = data?.data?.id;
                    let status = data?.data?.status;

                    setLoadingBoxOpen(false);
                    setSubmitChk(true);

                    if(status == 20){
                        window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/detailing/payment?id=${payId}&access_token=${localStorage.getItem("token")}`;
                    }else if(status == 1){
                        //결제 완료
                        setPaymentSuccessOpen(true);
                    }else{
                        setPopupData({
                            addClass:null,
                            title:"결제 실패",
                            text:"결제가 실패하였습니다.<br/>잠시 뒤 다시 시도해 주세요.",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    useEffect(() => {
        if(pageDate == "-" || !pageDate || pageTime == "" || !pageTime || !productData){
            navigate("/pageErr");
        }

        if(productData){
            setPrice(productData?.price);
        }
    }, []);

    useEffect(() => {
        let chkSet = [...checkItems];
        if(chkSet.length == termsData?.length + 1){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems]);

    useEffect(() => {
        let couponValue = coupon?.value ? coupon?.type === "percent" ? (Number(price) * (Number(coupon?.value) / 100)) : Number(coupon?.value) : 0;

        setTotalPrice(price - couponValue < 0 ? 0 : price - couponValue);
    }, [price,coupon]);

    useEffect(() => {
        if(couponData){
            setCouponList(couponData)
        }
    }, [couponApi]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="결제하기"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    <DetailItem
                        addClass=""
                        id={productData?.id}
                        name={productData?.name}
                        caption={productData?.contents}
                        requiredTime={productData?.requiredTime}
                        service={productData?.service}
                        notes={productData?.notes}
                        btnText={`${comFormat(productData?.price)}원`}
                        textType={true}
                    />
                </ItemSection>
                {couponList.length > 0 ? 
                <ItemSection>
                    <ItemSectionTitle
                        contents="쿠폰"
                    />
                    <CustomSelect
                        inputName="쿠폰"
                        placeholder="쿠폰을 선택하세요"
                        value={coupon?.id}
                        func={(name,val,subVal,type) => {
                            let dataSet = {
                                id:val,
                                name:name,
                                value:subVal,
                                type:type
                            }
                            setCoupon(dataSet);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        subValKey="value"
                        typeKey="type"
                        options={couponList}
                    />
                </ItemSection>
                :""}
                <ItemSection>
                    <ItemSectionTitle
                        contents={"결제수단"}
                    />
                    <PaymentBox>
                        {paymentList.map((item,i)=>(
                            <PaymentItem
                                key={i}
                                id={item.id}
                                name={item.name}
                                icon={item.icon}
                                disabled={item.disabled}
                                activeChk={paymentType}
                                func={(val)=>{setPaymentType(val)}}
                            />
                        ))}
                    </PaymentBox>
                </ItemSection>
                
                <ItemSection>
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems?.length === termsData?.length + 1 ? true : false}
                        // chkSubType=""
                        label="모든 약관에 동의합니다."

                    />
                    {termsData?.map((item, i) => (
                        <ChkBox
                            key={i}
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,item.id,item.title)}
                            checkedType={checkItems.includes(item.id) ? true : false}
                            label={`(필수) ${item.title}`}
                            chkFunc={(e) => rightOpen(item.id,item.title,item.contents)}
                        />
                    ))}
                    <ChkBox
                        key={termsData?.length}
                        addClass=""
                        func={(e) => handleSingleCheck(e.target.checked, 'month', '현재 등록된 차량번호에 정기권이 귀속됨을 확인합니다')}
                        checkedType={checkItems.includes('month') ? true : false}
                        label={`(필수) 현재 등록된 차량번호에 정기권이 귀속됨을 확인합니다`}
                    />
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={`총 ${comFormat(totalPrice)}원 결제`}
                        disabled={btnChk}
                        func={() => {payment()}}
                    />
                </BtnBox>
            </ContentsSection>
            <RightPopup
                topTitle={terms?.title}
                addClass={rightOpenType ? "active bottomBtn" : " bottomBtn"}
                closePopup={() => {setTerms({});setRightOpenType(false)}}
                openType={rightOpenType}
                bType={true}
            >
                <EditerBox addClass="termsText" data={terms?.contents}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="확인하고 동의합니다."
                        disabled={false}
                        func={() => {
                            if(!checkItems.includes(terms?.id)){
                                handleSingleCheck(true,terms?.id,terms?.title);
                            };
                        setTerms({});setRightOpenType(false)}}
                    />
                </BtnBox>
            </RightPopup>
            <PaymentSuccess
                open={paymentSuccessOpen}
                openClose={()=>{setPaymentSuccessOpen(false)}}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default DetailingPayment;